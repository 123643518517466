import React from "react"
import { trailingslashit } from "./helpers"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Image from "./image"

const CaseStudyCard = ({ data, as, ctaLabel, ctaUrl, className, isHeader }) => {
  const CardElement = as ? as : "article"

  return (
    <CardElement
      className={`caseStudy ${className} ${isHeader && "caseStudy--header"}`}
      style={{
        background: data.hero_background,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <Container>
        {data.device_mockup ? (
          <Row className="mobile-rtl equal" style={{ minHeight: 500 }}>
            <Col xs={12} lg={6} className="d-flex align-items-center">
              <div className="caseStudy__card__info text-center">
                {isHeader ? <h1 className="sr-only">{data.title}</h1> : null}
                <Image
                  resizeWidth={400}
                  className="caseStudy__card__logo"
                  src={data.logo}
                  alt={data.title}
                />
                <p className="caseStudy__card__excerpt">{data.summary}</p>
                {ctaUrl ? (
                  <Button
                    variant="outline-light"
                    href={trailingslashit(ctaUrl)}
                  >
                    {ctaLabel}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Image
              resizeWidth={800}
              src={data.device_mockup}
              alt={data.title}
              className="caseStudy__featured prlx__foreground"
              width="600px"
            />
          </Row>
        ) : (
          <Row className="mobile-rtl text-center">
            <Col xs={12} lg={12}>
              <div className="caseStudy__card__info">
                {isHeader ? <h1 className="sr-only">{data.title}</h1> : null}
                <Image
                  resizeWidth={300}
                  className="caseStudy__card__logo"
                  src={data.logo}
                  alt={data.title}
                />
                <p className="caseStudy__card__excerpt">{data.summary}</p>
                {ctaUrl ? (
                  <Button
                    variant="outline-light"
                    href={trailingslashit(ctaUrl)}
                  >
                    {ctaLabel}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </CardElement>
  )
}

export default CaseStudyCard
