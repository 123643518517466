import React from "react"
import { graphql } from "gatsby"
import { Parser } from "html-to-react"
import {
  linesToListItems,
  trailingslashit,
  linesToListComma,
} from "../components/helpers"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import CaseStudyCard from "../components/CaseStudyCard"
import SEO from "../components/seo"
import Image from "../components/image"

const CaseStudyPage = ({ data, pageContext, location }) => {
  const study = data.allButterPage.edges[0].node
  const { previous, next } = pageContext

  return (
    <Layout parallax={true} hero={<Hero />}>
      <SEO title={study.title} path={location.pathname} />
      <CaseStudyCard
        data={study}
        as="section"
        ctaLabel="View Project"
        isHeader={true}
      />
      <section className="cs-border-bottom p-0">
        <Container>
          <Row>
            <Col
              xs={10}
              md={study.services_used.length > 0 ? 6 : 6}
              className="py-3"
            >
              <hr className="hr-cs" />
              <h2 className="h6">Project</h2>
              <p>{study.project_brief}</p>
            </Col>
            {study.services_used.length > 0 ? (
              <Col xs={10} md={3} className="py-3">
                <hr className="hr-cs" />
                <h2 className="h6">Services Used</h2>
                <p>{linesToListComma(study.services_used)}</p>
              </Col>
            ) : (
              ""
            )}
            {study.disciplines_used.length > 0 ? (
              <Col xs={10} md={3} className="py-3">
                <hr className="hr-cs" />
                <h2 className="h6">Technologies Used</h2>
                <p>{linesToListComma(study.disciplines_used)}</p>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </section>

      {study.video_url ? (
        <Container as="section">
          <Row>
            <Col>
              <p class="embed-responsive embed-responsive-16by9">
                <video
                  width="300"
                  height="150"
                  controls="controls"
                  src={study.video_url}
                >
                  {" "}
                  Your browser does not support the HTML5 Video element.{" "}
                </video>
              </p>
            </Col>
          </Row>
        </Container>
      ) : (
        ""
      )}

      <Container as="section">
        <Row className="mobile-rtl-767">
          <Col xs={12} md={study.the_background_image ? 6 : 12}>
            <hr className="hr-disciplines" />
            <h2 className="mb-4">
              The
              <br />
              Background
            </h2>
            {new Parser().parse(study.the_background)}
          </Col>
          {study.the_background_image ? (
            <Col xs={12} md={6} className="text-center">
              <Image
                resizeWidth={800}
                className="img-fluid caseStudy__image prlx__foreground"
                src={study.the_background_image}
                alt={study.title}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
      <Container as="section">
        <Row>
          {study.the_challenge_image ? (
            <Col xs={12} md={6} className="text-center">
              <Image
                resizeWidth={800}
                className="img-fluid caseStudy__image prlx__foreground"
                src={study.the_challenge_image}
                alt={study.title}
              />
            </Col>
          ) : (
            ""
          )}
          <Col
            xs={12}
            md={study.the_challenge_image ? 6 : 12}
            className="challenge-wrapper"
          >
            <hr className="hr-disciplines" />
            <h2 className="mb-4">
              The
              <br />
              Challenge
            </h2>
            {new Parser().parse(study.the_challenge)}
          </Col>
        </Row>
      </Container>
      {study.features_process.length > 0 ? (
        <Container as="section">
          <hr className="hr-disciplines" />
          <h2 className="mb-4">
            Features
            <br />
            &amp; Process
          </h2>
          <Row>
            {study.features_process.map(e => (
              <Col xs={12} md={4}>
                <h3 className="h5">{e.title}</h3>
                <p className="mt-4">{e.description}</p>
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        ""
      )}
      {study.screenshots.length > 0 ? (
        <Container as="section">
          {study.screenshots.map(e => (
            <Row>
              <Col>
                <p>
                  <Image
                    resizeWidth={1920}
                    className="img-fluid prlx__foreground"
                    src={e.image}
                    alt={e.description}
                  />
                </p>
              </Col>
            </Row>
          ))}
        </Container>
      ) : (
        ""
      )}

      {study.the_result_image ? (
        <Container as="section" className="results-wrapper text-center">
          <hr className="hr-disciplines" />
          <h2 className="mb-4">
            The Result{" "}
            {study.the_result_subtitle ? "- " + study.the_result_subtitle : ""}
          </h2>
          {new Parser().parse(study.the_result)}
          <Image
            resizeWidth={1920}
            className="img-fluid caseStudy__image prlx__foreground"
            src={study.the_result_image}
            alt={study.the_result_subtitle}
          />
        </Container>
      ) : (
        <Container as="section" className="results-wrapper">
          <hr className="hr-disciplines" />
          <h2 className="mb-4">
            The Result{" "}
            {study.the_result_subtitle ? "- " + study.the_result_subtitle : ""}
          </h2>
          {new Parser().parse(study.the_result)}
        </Container>
      )}

      {study.testimonial_quote ? (
        <Container as="section">
          <h2 className="text-center">Client Testimonial</h2>
          <blockquote className="block-testimonial text-center">
            <p className="quote">{study.testimonial_quote}</p>
            <div className="text-center pt-3 pb-3">
              <Image
                resizeWidth={400}
                className="img-testimonial img-fluid prlx__foreground"
                src={study.testimonial_image}
                alt={study.testimonial_name}
              />
            </div>
            <strong>{study.testimonial_name}</strong>
            <br />
            <em>{study.testimonial_position}</em>
          </blockquote>
        </Container>
      ) : (
        ""
      )}
      <Container as="section">
        <div className="text-center">
          {!previous ? null : (
            <Button
              href={"/our-work/" + trailingslashit(previous.slug)}
              variant="outline-primary"
              className="m-3"
            >
              Prev Case
            </Button>
          )}
          {!next ? null : (
            <Button
              href={"/our-work/" + trailingslashit(next.slug)}
              variant="primary"
              className="m-3"
            >
              Next Case
            </Button>
          )}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CaseStudyPageBySlug($slug: String!) {
    allButterPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          video_url
          device_mockup
          hero_background
          featured_image
          logo
          alternative_logo
          summary
          project_brief
          services_used
          the_background
          the_background_image
          the_challenge
          the_challenge_image
          features_process {
            title
            description
          }
          screenshots {
            image
            description
          }
          the_result_subtitle
          the_result
          the_result_image
          disciplines_used
          testimonial_image
          testimonial_quote
          testimonial_name
          testimonial_position
        }
      }
    }
  }
`

export default CaseStudyPage
